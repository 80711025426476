import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './css/normalize.css';
import './css/sf.css';
import './style.scss';

import App from './components/App';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import FinalStep from './components/Steps/FinalStep';
import CloseSession from './components/Steps/CloseSession';
import { Provider } from 'react-redux';
import { store } from './app/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
            <Route path='/widget' element={<App />} />
            <Route path='/final' element={<FinalStep />} />
            <Route path='/end' element={<CloseSession />} />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>
);