import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import Localized from "../../../../Localized";
import {
    useUpdateProductsMutation,
    useSetPaymentMethodMutation,
} from "../../../../redux/api";
import {
    changeStep,
    isButtonLoader,
    openInputEmail,
} from "../../../../redux/slices/windowSlice";
import { handleConfirmCartFlow } from "../../../../redux/slices/orderSlice";
//handleConfirmCartFlow
import "./style.scss";
import TagManager from "react-gtm-module";

const ButtonFooter = () => {
    const dispatch = useAppDispatch();
    const { stepCurrent, loaderButton } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const { totalCount, selectedPaymentMethod, goods, selected, customerEmail, eventId } =
        useAppSelector((state: RootState) => state.orderSlice);
    const { mail, isMailValid } = useAppSelector(
        (state: RootState) => state.userSlice
    );
    const [sendCart] = useUpdateProductsMutation();
    const [setPaymentMethod] = useSetPaymentMethodMutation();

    const handleChange = async () => {
        if (!loaderButton) {
            switch (stepCurrent) {
                case 0:
                    dispatch(isButtonLoader(true));

                    const cartData = {
                        cart: goods
                            .filter((item) => item.count > 0)
                            .map((item) => ({
                                id: item.categoryId,
                                count: item.count,
                            })),
                        event_id: eventId,
                    };

                    let amount = 0;
                    let service = 0;
                    let totalPrice = 0;

                    goods.filter((item) => selected.includes(item.categoryId))
                    .sort((a, b) => selected.indexOf(a.categoryId) - selected.indexOf(b.categoryId))
                    .map((item) => {
                        amount = amount + Number(item.prices.standart) * item.count;
                        service = service + Number(item.prices.service) * item.count;
                        totalPrice = amount + service;
                        return true;
                    });
                    
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "addToCart",
                            total: totalPrice,
                            currency: "BYN",
                            items: goods
                                .filter((item) => item.count > 0)
                                .map((item) => ({
                                    id: item.categoryId,
                                    name: item.title,
                                    price: item.prices.standart,
                                    count: item.count,
                                })),
                        },
                    });

                    sendCart(cartData);
                    break;
                case 1:
                    dispatch(isButtonLoader(true));
                    dispatch(handleConfirmCartFlow());
                    break;

                case 2:
                    if (selectedPaymentMethod) {
                        dispatch(openInputEmail(true));
                    } else {
                    }

                    break;
            }
        }
    };

    const getBtnText = () => {
        if (totalCount === 0) {
            return Localized.BtnOrderEmpty;
        }
        switch (stepCurrent) {
            case 0:
                return Localized.BtnLookOrder;
            case 1:
            case 2:
                return Localized.BtnPlaceOrder;
            case 3:
                return Localized.BtnPayOrder;
        }
    };

    const getClassButton = () => {
        if (
            (stepCurrent > 0 && totalCount === 0) ||
            (!selectedPaymentMethod && stepCurrent === 2)
        ) {
            return "button-footer button-footer_empty";
        } else {
            return "button-footer button-footer_simple";
        }
    };

    // const colorBtn = () => {
    //     if(!selectedPaymentMethod) {
    //         return "#34C759";
    //     }

    //     return selectedPaymentMethod.icon_color;
    // }

    const colorBtn = () => {
        return !selectedPaymentMethod
            ? "#34C759"
            : selectedPaymentMethod.icon_color;
    };

    const lightenDarkenColor = (col, amt) => {
        let usePound = false;
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        const num = parseInt(col, 16);
        let r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let b = ((num >> 8) & 0x00ff) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        let g = (num & 0x0000ff) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    };

    // Использование
    const baseColor = "#6699CC"; // Пример цвета в формате HEX
    const lighterColor = lightenDarkenColor(baseColor, 20); // Чуть светлее
    const darkerColor = lightenDarkenColor(baseColor, -20); // Чуть темнее

    // Установите переменную в корневом элементе (или любом другом подходящем элементе)
    useEffect(() => {
        document.documentElement.style.setProperty(
            "--button-background-color",
            colorBtn()
        );

        const baseColor = colorBtn(); // Функция, возвращающая основной цвет кнопки
        const lighterColor = lightenDarkenColor(baseColor, 20); // Чуть светлее
        const darkerColor = lightenDarkenColor(baseColor, -20); // Чуть темнее

        document.documentElement.style.setProperty(
            "--button-background-color",
            baseColor
        );
        document.documentElement.style.setProperty(
            "--button-hover-color",
            lighterColor
        );
        document.documentElement.style.setProperty(
            "--button-active-color",
            darkerColor
        );
    }, [selectedPaymentMethod]);

    const shadowClass = stepCurrent === 0 ? "button-footer_shadow" : "";

    return (
        <div
            className={`${getClassButton()} ${shadowClass}`}
            style={
                totalCount > 0 || stepCurrent > 0
                    ? { transform: "translateY(0)" }
                    : {}
            }>
            <div className="button-footer_container">
                <div
                    className="button-footer_wrapper"
                    onClick={() => {
                        handleChange();
                    }}
                    style={{ background: colorBtn() }}>
                    <div
                        className="loader"
                        style={
                            loaderButton
                                ? { display: "block" }
                                : { display: "none" }
                        }></div>
                    <span
                        className="button-footer_text"
                        style={
                            !loaderButton
                                ? { display: "block" }
                                : { display: "none" }
                        }>
                        {getBtnText()}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ButtonFooter;
