import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../app/store';
import Localized from '../../Localized';
import { ProductInfo } from '../types/order';

import { UserInfo } from '../types/user';

interface ProductResponse {
  response: string;
  method: string;
  token: string;
  maxTotal: number;
  service: string;
  link: string;
  items: ProductInfo[];
}

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const state = api.getState() as RootState;
  const baseUrl = state.userSlice.baseUrl;

  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

  return rawBaseQuery(args, api, extraOptions);
};


export const api = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({

    getToken: builder.mutation<any, { event_id: number; fingerprint: string }>({
      query: (args) => ({
        url: `session/create?lang=${Localized.getLanguage()}&id=${args.fingerprint}&event_id=${args.event_id}`,
        method: 'GET',
      }),
    }),   
    
    checkToken: builder.mutation<any, void>({
      query: () => ({
        url: `session/check`,
        method: 'POST',
      }),
    }),  

    closeSession: builder.mutation<UserInfo[], void>({
      query: () => ({
        url: '/session/close',
        method: 'POST',
      }),
    }),

    getProducts: builder.query<ProductResponse, { product_id: number; event_id: number }>({
      query: ({ product_id, event_id }) => ({
        url: `/products?product_id=${product_id}&event_id=${event_id}`,
        method: 'GET',
      }),
    }), 
    
    getMaxCountProducts: builder.query<ProductResponse, { event_id: number; _timestamp: any }>({
      query: ({ event_id }) => ({
        url: `/products?product_id=0&event_id=${event_id}`,
        method: 'GET',
      }),
    }), 

    updateProducts: builder.mutation<any, {}>({
      query: (data) => ({
        url: '/cart',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    }),

    confirmProducts: builder.mutation<any, {}>({
      query: (data) => ({
        url: '/cart',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    }),

    sendContactInfo: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: '/customer',
        method: 'POST',
        body: formData,
      }),
    }),

    setEmail: builder.mutation<any, {}>({
      query: (data) => ({
        url: '/customer',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    }),

    getPaymentMethods: builder.mutation<any, void>({
      query: () => ({
        url: '/payment/methods',
        method: 'POST',
      }),
    }),
    
    setPaymentMethod: builder.mutation<any, { payment_method: number, discount_id: number | null, email: string }>({
      query: (data) => ({
        url: '/payment/methods',
        method: 'PUT',
        body: data,
      }),
    }),
    
    

  }),
  
});

export const { 
  useGetTokenMutation, 
  useCheckTokenMutation,
  useCloseSessionMutation, 
  useGetProductsQuery,
  useGetMaxCountProductsQuery,
  useUpdateProductsMutation, 
  useSendContactInfoMutation, 
  useSetEmailMutation, 
  useGetPaymentMethodsMutation, 
  useSetPaymentMethodMutation 
} = api;