import Header from "../../Window/Header";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./anim.json";
import ticketJump from "./ok.json";
import ticketBtn from "./anim-btn.json";
import Localized from "../../../Localized";
import TagManager from 'react-gtm-module';

import { saveAs } from "file-saver";

import "./style.scss";
//import wallet from '../../../img/finalStep/add-to-wallet-btn.png';
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { changeStep } from "../../../redux/slices/windowSlice";

import okPayment from "../../../img/finalStep/okPayment.svg";

const FinalStep = () => {
    const [url, setUrl] = useState<Blob | null>(null);
    const [baseURL, setBaseURL] = useState(process.env.REACT_APP_API_URL);
    const [attemptCount, setAttemptCount] = useState(0); // Добавляем счетчик попыток

    const dispatch = useAppDispatch();
    dispatch(changeStep(5));

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    let emailUrl = searchParams.get("email");
    let token = searchParams.get("token");
    let testmode = searchParams.get("testmode");

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
              event: 'finalStep'
            },
        });
        if(testmode){
            setBaseURL(process.env.REACT_APP_QA__URL);
        }
    }, []);
    
    useEffect(() => {
        const fetchTicket = () => {
            axios({
                method: "GET",
                url: `${baseURL}/tickets?token=${token}`,
                responseType: "arraybuffer",
            })
                .then(function (response) {
                    if (response.status === 200) {
                        setUrl(
                            new Blob([response.data], {
                                type: "application/pdf",
                            })
                        );
                    } else {
                        console.log(
                            "Билет еще не готов, получен статус: ",
                            response.status
                        );
                        if (attemptCount < 5) {
                            // Запланируем следующую попытку, не увеличивая здесь счетчик
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    // Не повторяем попытку здесь для предотвращения бесконечного цикла
                });

            if (attemptCount < 5) {
                // Устанавливаем таймер для следующей попытки
                const timerId = setTimeout(() => {
                    setAttemptCount(attemptCount + 1);
                }, 5000);

                // Очищаем таймер при размонтировании компонента
                return () => clearTimeout(timerId);
            }
        };

        fetchTicket();
        // Добавляем attemptCount в массив зависимостей
    }, [token, attemptCount]);

    const handleDownload = () => {
        if (!url) return;
        saveAs(url, "ticket.pdf");
    };

    const getTicketBtn = () => {
        if (attemptCount < 5) {
            return (
                <div className="final_btns">
                    <div className="final_btns_ticket">
                        <div
                            className="loader"
                            style={{ display: "block" }}></div>
                    </div>
                </div>
            );
        } else if (attemptCount == 5) {
            return (
                <h2 className="final_text">
                    Нам нужно чуть больше времени для генерации билетов.
                    <br />
                    Мы отправим их на почту!
                </h2>
            );
        }
    };

    return (
        <div className="modal">
            <Header />
            <div className="order-mode final">
                <div className="final_container">
                    <div className="final_wrapper">
                        <div className="final_animate">
                            <div className="final_animate_file">
                                <Lottie
                                    animationData={ticketJump}
                                    loop={false}
                                />
                            </div>
                        </div>
                        <h1 className="final_title">
                            {Localized.FinalThankYou}
                        </h1>
                        <h2 className="final_text">
                            {Localized.formatString(Localized.FinalText, {
                                email: <b>{emailUrl}</b>,
                            })}
                        </h2>
                        {url ? (
                            <>
                                <div className="final_btns">
                                    <div
                                        className="final_btns_ticket"
                                        onClick={() => handleDownload()}>
                                        <span className="final_btns_ticket_text">
                                            {Localized.FinalDownloadTicket}
                                        </span>
                                    </div>
                                </div>
                                <Lottie
                                    animationData={ticketBtn}
                                    loop={false}
                                    className="final_btns-animate"
                                />
                            </>
                        ) : (
                            getTicketBtn()
                        )}
                    </div>
                </div>
            </div>
            <div className="anim">
                <Lottie animationData={groovyWalkAnimation} loop={false} />
            </div>
        </div>
    );
};

export default FinalStep;
