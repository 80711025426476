import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../api';
import { WindowInfo } from '../types/window';


const initialState: WindowInfo = {
  stepTotal: 5,
  stepCurrent: 0,
  isEmailStep: false,
  loaderWindow: true,
  loaderButton: false,
  checkoutMsg: '',
  globalMsg: '',
  headerCutsom: '',
  isTestMode: false
};

const windowInfo = createSlice({
  name: 'window',
  initialState,
  reducers: {
    openInputEmail(state, {payload}) {
      state.isEmailStep = true;
    }, 

    closeInputEmail(state, {payload}) {
      state.isEmailStep = false;
    },

    changeStep(state, action: PayloadAction<number>) {

        if(action.payload === 5) {
          state.stepCurrent = 5;
        } else if(action.payload === 4) {
          state.stepCurrent = 4;
        } else if(action.payload === 3) {
          state.stepCurrent = 3;
        } else if(action.payload === 2) {
          state.stepCurrent = 2;
        } else if(action.payload === 1) {
          if(state.stepCurrent + 1 <= state.stepTotal) {
            state.stepCurrent++;
          }
        } else {
          if(state.stepCurrent > 0) {
            if(state.stepCurrent == 3) {
              state.stepCurrent = 1;
            } else {
              state.stepCurrent--;
            }
            
          }
        }
    },

    isMainLoader(state, action: PayloadAction<boolean>) {
      state.loaderWindow = action.payload;
    },

    isButtonLoader(state, action: PayloadAction<boolean>) {
      state.loaderButton = action.payload;
    },
    setCheckoutMsg(state, action: PayloadAction<string>) {
      state.checkoutMsg = action.payload;
    },
    setGlobalMsg(state, action: PayloadAction<string>) {
      state.globalMsg = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(
      api.endpoints.getToken.matchFulfilled,
        (state, { payload }) => {
          if(payload['state'] === "active") {
            state.stepCurrent = 0;
            state.isTestMode = payload['is_testmode'] ?? false;
          } else if(payload['state'] === "waiting") {
            state.stepCurrent = -1;
            state.loaderWindow = false;
          }
        }
    )  
    .addMatcher(
      api.endpoints.getToken.matchRejected,
      (state, action) => {
        if (action.payload) { 
          let code = typeof action.payload.status === 'number' ? action.payload.status : action.payload['originalStatus'];
          switch (code) {
            case 503:
              state.stepCurrent = -1;
              state.globalMsg = "Мы проводим техничекие работы, попробуйте зайти чуть позже. Спасибо за ожидание!"
              break;
            case 429:
              state.stepCurrent = -1;
              break;
            case 404:
              state.stepCurrent = -1;
              state.headerCutsom = action.payload.data['error']['error_title'];
              state.globalMsg = action.payload.data['error']['error_msg'];
              break;
              
            default:
              state.stepCurrent = 5;
              break;
          }
        } else if (action.error) { 
          state.stepCurrent = -1;
        }
      }
    )
    .addMatcher(
      api.endpoints.closeSession.matchFulfilled,
      (state, { payload }) => {
        state.isEmailStep = false;
        state.stepCurrent = 5;
      }
    )
    .addMatcher(
      api.endpoints.checkToken.matchFulfilled,
      (state, { payload }) => {
        if (payload['state'] === "active") {
          if(state.stepCurrent == -1) {
            state.stepCurrent = 0;
          }
        } else if (payload['state'] === "expired") {
          state.stepCurrent = 5;
        }
      }
    )
    .addMatcher(
      api.endpoints.updateProducts.matchFulfilled,
      (state, { payload }) => {
        if(payload.response === 'ok') {
          state.loaderButton = false;
          if(state.stepCurrent + 1 <= state.stepTotal) {
            state.stepCurrent++;
          }
        } else {
          state.loaderButton = false;
        }
      }
    )
    // .addMatcher(
    //   api.endpoints.updateProducts.matchRejected,
    //   (state, { payload }) => {
    //       state.loaderButton = false;
    //   }
    // )
    .addMatcher(
      api.endpoints.updateProducts.matchRejected,
      (state, action) => {
        if (action.payload) { 
          
          
          switch (action.payload.status) {
            case 503:
              state.stepCurrent = -1;
              state.globalMsg = "Мы проводим техничекие работы, попробуйте зайти чуть позже. Спасибо за ожидание!"
              break;
            case 429:
              state.stepCurrent = -1;
              break;
              
            default:
              state.stepCurrent = 5;
              break;
          }
        } else if (action.error) { 
          state.stepCurrent = 5;
        }
      }
    )
    .addMatcher(
      api.endpoints.getPaymentMethods.matchFulfilled,
      (state, { payload }) => {
        state.loaderButton = false;
      }
    )
    .addMatcher(
      api.endpoints.confirmProducts.matchRejected,
      (state, action) => {
        if (action.payload) { 
          switch (action.payload.status) {
            case 503:
              state.stepCurrent = -1;
              state.globalMsg = "Мы проводим техничекие работы, попробуйте зайти чуть позже. Спасибо за ожидание!"
              break;
            case 429:
              state.stepCurrent = -1;
              break;
              
            default:
              state.stepCurrent = 5;
              break;
          }
        } else if (action.error) { 
          state.stepCurrent = 5;
        }
      }
    )
    .addMatcher(
      api.endpoints.setPaymentMethod.matchFulfilled,
      (state, { payload }) => {
        state.stepCurrent = 3;
      }
    )
    .addMatcher(
      api.endpoints.setPaymentMethod.matchRejected,
      (state, action) => {
        if (action.payload) { 
          switch (action.payload.status) {
            case 503:
              state.stepCurrent = -1;
              state.globalMsg = "Мы проводим техничекие работы, попробуйте зайти чуть позже. Спасибо за ожидание!"
              break;
            case 429:
              state.stepCurrent = -1;
              break;
              
            default:
              state.stepCurrent = 5;
              state.isEmailStep = false;
              break;
          }
        } else if (action.error) { 
          state.stepCurrent = 5;
          state.isEmailStep = false;
        }
      }
    )
    .addMatcher(
      api.endpoints.setEmail.matchFulfilled,
      (state, action) => {
          state.isEmailStep = false;
      }
    )
    .addMatcher(
      api.endpoints.setEmail.matchRejected,
      (state, action) => {
        if (action.payload) { 
          switch (action.payload.status) {
            case 400:
              break;
              case 503:
                state.stepCurrent = -1;
                state.globalMsg = "Мы проводим техничекие работы, попробуйте зайти чуть позже. Спасибо за ожидание!"
                break;
              case 429:
                state.stepCurrent = -1;
                break;
              
            default:
              state.stepCurrent = 5;
              state.isEmailStep = false;
              break;
          }
        } else if (action.error) { 
          state.stepCurrent = 5;
          state.isEmailStep = false;
        }
      }
    )
  },
});

export const { changeStep, isMainLoader, isButtonLoader, setCheckoutMsg, setGlobalMsg, openInputEmail, closeInputEmail } = windowInfo.actions;
export default windowInfo.reducer;